import React, { Component } from "react";
import socketIOClient from "socket.io-client";
import SelectDate from './components/selectdate';
import CategoryList from './components/categoryprice';
import ChooseSeat from './components/chooseseat';
import Welcome from './components/welcome';
import Bookingconfirmed from './components/bookingconfirmed';
import $ from 'jquery';
import PaymentOption from "./components/prepayment";
import moment from 'moment';
import AliceCarsourel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import Pairing from './components/pairing';
import './App.css'
class WhiteLabel extends Component {
  constructor(props) {
  super(props);
  this.state = {
  response: false,
  html:'',
  randomString: "",
  endpoint: process.env.REACT_APP_ENDPOINT,
   eventShowTimes: {},
   searchresponse:{},
   seatoverview:{},
   seatdata:{},
   seatdate:{},
   date_and_time_control:{},
   seat_data:{},
   get_ticket_type:{},
   getPrePaymentOrder: {},
   getTransctions: {},
   payment_completed:{},
   calender_page:null,
   mymodalValue:1,
   get_ticket_price_info:{},
   scrollPaymentVertical:{},
   scrollpaymentHorizontal:{},
   scrollagreeVertical:{},
   scrollagreeHorizontal:{},
   scrollconfirmedVertical:{},
   scrollconfirmedHorizontal:{},
   scrollseatmapleft:{},
   scrollseatmaptop:{},
   session_key: '',
   uuid:'',
   get_ticket_info_onchange:{},
   triggerevent:{},
   randomString:'',
   zoomdata:null,
   languageindex:0,
   calender_seat_selection:null,
   first_screen_user_key:"",
   pricedetails:{},
   scrollvalue:{},
   agree_checkbox:false,
   agreedata:"",
   paymenterror:"",
   iccthumbnail:"",
   language_conversions:[{
     paringid:"Pairing ID",
     processing:"Processing",
     availabledateandtime:"Available Date & Time",
     dateandtime:"Date & Time",
     category:"Category",
     priceclass:"Price Class",
     january:"January",
     febrary:"February",
     march:"March",
     april:"April",
     may:"May",
     june:"June",
     july:"July",
     august:"August",
     septemper:"September",
     october:"October",
     november:"November",
     december:"December",
     mon:"Mon",
     tue:"Tue",
     wed:"Wed",
     thu:"Thu",
     fri:"Fri",
     sat:"Sat",
     sun:"Sun",
     soldout:"Sold Out",
     fastfilling:"Fast Filling",
     available:"Available",
     eventavaliability:"Event Availability",
     seatsavailable:"Seats Available",
     forseatselection:"For seat selection",
     priceclass:"Price Class",
     seatplannotdrawn:"Seat plan is not drawn to scale | Colour indicates price category | Ticket prices exclude booking fees",
     seatinformation:"Seat information",
     seatdetails:"Seat Details",
     row:"Row",
     goodday:"Good day",
     goodafternoon:"Good afternoon",
     goodevening:"Good evening",
     goodmorning:"Good morning",
     yourcartitems:"Your Cart Items",
     no:"No.",
     item:"Item",
     description:"Description",
     level:"Level",
     section:"Section",
     row:"Row",
     seatno:"Seat No.",
     priceclass:"Price Class",
     unitprice:"Unit Price",
     quantity:"Quantity",
     subtotal:"Sub Total",
     event:"Event",
     granttotal:"Grand Total",
     bookingfee:"Booking Fee",
     paymentoption:"Payment Options",
     agreetoterms:"I agree to the Terms and Conditions",
     bookingconfirmed:"Booking Confirmed",
     transactioncompleted:"Transaction Completed",
     transactionid:"Transaction ID",
     payment:"Payment",
     payableamount:"Payable Amount",
     received:"Received",
     change:"Change",
     printing:"Printing",
     yourticketgettingready:"Your Ticket getting ready",
     thankyou:"Thank You",
     jan:"Jan",
    feb:"Feb",
    mar:"Mar",
    apr:"Apr",
    may:"May",
    jun:"Jun",
    jul:"Jul",
    aug:"Aug",
    sep:"Sep",
    oct:"Oct",
    nov:"Nov",
    dec:"Dec",
    nopreview:"No Preview Available",
    paymentunsuccessful:"Payment Unsuccessful",
    iagree:"I Agree",
    show:"Show",
    seatseleted:"Seat Selected",
    unavailableseat:"Unavailable",
    availableseat:"Available",
    seatnotavailable:"Seating plan not available for this event "
   },{
    paringid:"配對號碼",
     processing:"載入中",
     availabledateandtime:"節目日期及時間",
     dateandtime:"日期及時間",
     category:"類別",
     priceclass:"門票種類",
     january:"01",
     febrary:"02",
     march:"03",
     april:"04",
     may:"05",
     june:"06",
     july:"07",
     august:"08",
     septemper:"09",
     october:"10",
     november:"11",
     december:"12",
     mon:"星期一",
     tue:"星期二",
     wed:"星期三",
     thu:"星期四",
     fri:"星期五",
     sat:"星期六",
     sun:"星期日",
     soldout:"未能提供",
     fastfilling:"尚餘少量",
     available:"可供購票",
     eventavaliability:"節目銷售情況",
     seatsavailable:"可供購票",
     forseatselection:"座位表",
     priceclass:"門票種類",
     seatplannotdrawn:"座位表不按照實際比例繪畫 | 票價區以不同顏色顯示 | 票價並不包括手續費",
     seatinformation:"座位資料",
     seatdetails:"座位詳情",
     row:"行",
     goodday:"您好",
     goodafternoon:"午安",
     goodevening:"您好",
     goodmorning:"早晨",
     yourcartitems:"購物籃詳情",
     no:"項目",
     item:"類型",
     description:"內容",
     level:"層",
     section:" 段",
     row:"行",
     seatno:"座位",
     priceclass:"門票種類",
     unitprice:"每張票價",
     quantity:"數量",
     subtotal:"合共",
     event:"節目",
     granttotal:"總數",
     bookingfee:"手續費",
     paymentoption:"付款方式",
     agreetoterms:"本人同意並接受條款及細則",
     bookingconfirmed:"交易已完成",
     transactioncompleted:"購票交易確認",
     transactionid:"交易編號",
     payment:"付款方式",
     payableamount:"付款金額",
     received:"已收取金額",
     change:"找續",
     printing:"列印中",
     yourticketgettingready:"門票列印中",
     thankyou:"多謝選購 ",
     jan:"01",
    feb:"02",
    mar:"03",
    apr:"04",
    may:"05",
    jun:"06",
    jul:"07",
    aug:"08",
    sep:"09",
    oct:"10",
    nov:"11",
    dec:"12",
    nopreview:"無預覽",
    paymentunsuccessful:"付款失敗",
    iagree:" 同意",
    show:"場節目",
    seatseleted:"座位選擇",
    unavailableseat:"不可選",
    availableseat:"可選",
    seatnotavailable:"本節目不設座位表"
   },{
    paringid:"配对号码",
    processing:"载入中",
    availabledateandtime:"节目日期及时间",
    dateandtime:"日期及时间",
    category:"类别",
    priceclass:"门票种类",
    january:"01",
    febrary:"02",
    march:"03",
    april:"04",
    may:"05",
    june:"06",
    july:"07",
    august:"08",
    septemper:"09",
    october:"10",
    november:"11",
    december:"12",
    mon:"星期一",
    tue:"星期二",
    wed:"星期三",
    thu:"星期四",
    fri:"星期五",
    sat:"星期六",
    sun:"星期日",
    soldout:"未能提供",
    fastfilling:"尚余少量",
    available:"可供购票",
    eventavaliability:"节目销售情况",
    seatsavailable:"可供购票",
    forseatselection:"座位表",
    priceclass:"门票种类",
    seatplannotdrawn:"座位表不按照实际比例绘画| 票价区以不同颜色显示| 票价并不包括手续费",
    seatinformation:"座位资料",
    seatdetails:"座位详情",
    row:" 行",
    goodday:"您好",
    goodafternoon:"午安",
    goodevening:"您好",
    goodmorning:"早晨",
    yourcartitems:"购物篮详情",
    no:"项目",
    item:"类型",
    description:"内容",
    level:"层",
    section:" 段",
    row:" 行",
    seatno:"座位",
    priceclass:"门票种类",
    unitprice:"每张票价",
    quantity:"数量",
    subtotal:"合共",
    event:"节目",
    granttotal:"总数",
    bookingfee:"手续费",
    paymentoption:"付款方式",
    agreetoterms:"本人同意并接受条款及细则",
    bookingconfirmed:"交易已完成",
    transactioncompleted:"购票交易确认",
    transactionid:"交易编号",
    payment:"付款方式",
    payableamount:"付款金额",
    received:"已收取金额",
    change:"找续",
    printing:"列印中",
    yourticketgettingready:"門票列印中",
    thankyou:"多谢选购 ",
    jan:"01",
    feb:"02",
    mar:"03",
    apr:"04",
    may:"05",
    jun:"06",
    jul:"07",
    aug:"08",
    sep:"09",
    oct:"10",
    nov:"11",
    dec:"12",
    nopreview:"无预览",
    paymentunsuccessful:"付款失败",
    iagree:" 同意",
    show:"场节目",
    seatseleted:"座位选择",
    unavailableseat:"不可选",
    availableseat:"可选",
    seatnotavailable:"本节目不设座位表"
   }],
   basicConfig: {
     seatNumberingDispalyEnable: true
   },
   images: {
     startupImgs : [],
     logoImage: '',
     defaultEventImage: '',
     defaultSeatmapImage: '',
     startUpImgAutoPlayInterval: ''
   }
  };
  }
  
  componentWillMount() {
  const { endpoint } = this.state;
  let THIS=this;
  const socket = socketIOClient(endpoint,{ transports: ['websocket','polling']});
  let queryString=window.location.href;
  const newBaseUrl = queryString.substring(0, queryString.lastIndexOf('/'));

  //queryString=queryString.replace(window.location.origin+'/mbs/',"")
  queryString=queryString.replace(newBaseUrl+'/',"")
  
  socket.on('connect', function () {
    if(queryString.length >= 4 ){
      socket.client_key=queryString
    }
    else
    socket.client_key = Math.random().toString(36).substring(7);
    THIS.setState({randomString:socket.client_key});
    socket.emit('join', {client_key: socket.client_key});

  });
  
  $.get('https://www.cloudflare.com/cdn-cgi/trace', function(ipdata) {
    let splitdata=ipdata.split("\n");
    splitdata=splitdata[2].replace('ip=','');
    THIS.setState({ipaddress:splitdata})
  });

  socket.on("data_response", data => {
    console.log("data response", data);
    switch(data.value)
    {
    case "seat_Offer":
      if(data.data.httpStatus !== '400' || data.data.httpStatus !== '500')
      return this.setState({pricedetails:{},session_key:data.client_key,client_key:data.session_key,calender_page:null,get_ticket_info_onchange :{},seatdate:data.data,zoomdata:null,response:{}, searchresponse:{},eventShowTimes:{},get_ticket_type:{},});
      else
      return this.setState({seatdate: {} });
    case "seat_overview":
        if(data.data.httpStatus !== '400' || data.data.httpStatus !== '500')
        return this.setState({seat_data:{},session_key:data.client_key,client_key:data.session_key,seatoverview:data.data,get_ticket_info_onchange :{},getPrePaymentOrder:{}, calender_page:null,searchresponse:{},eventShowTimes:{},get_ticket_type:{}});
        else
        return this.setState({ seatoverview: {},get_ticket_info_onchange :{},getPrePaymentOrder:{}, calender_page:null,searchresponse:{},eventShowTimes:{},get_ticket_type:{}});
    case "seat_pricetable":
      if(data.data.httpStatus !== '400' || data.data.httpStatus !== '500')
      return this.setState({seatdate:{},seat_data:{},session_key:data.client_key,client_key:data.session_key,pricedetails:data.data,get_ticket_info_onchange :{},getPrePaymentOrder:{}, calender_page:null,searchresponse:{},eventShowTimes:{},get_ticket_type:{}});
      else
      return this.setState({seatdate:{},pricedetails: {},get_ticket_info_onchange :{},getPrePaymentOrder:{}, calender_page:null,searchresponse:{},eventShowTimes:{},get_ticket_type:{}});
      case 'agree_open':
        return this.setState({ pricedetails:{},controlagreeopen:data.data,controlagreeclose:null,agreedata:data.data });
      default:
      return this.state;
      
    }
  });
  
  socket.on("control_response", data => {
    console.log("control response", data);
    switch(data.value)
    {
    case "basic_config":
      return this.setState({basicConfig: data.data});
    case "images":
      return this.setState({images: data.data});
    case "payment_error":
      return this.setState({paymenterror:data.data});
    case "calender_seat_selection":
      return this.setState({calender_seat_selection:data.data});
    case "languageindex":
      return this.setState({languageindex:data.data,controlagreeopen:2}); //,controlagreeopen:null
    case "zoomdata":
      return this.setState({zoomdata:data.data });
    case "logout":
        return this.setState({session_key:data.client_key,client_key:data.session_key,get_ticket_info_onchange :{},calender_page:null,scrollconfirmedHorizontal:{},scrollconfirmedVertical:{},scrollpaymentHorizontal:{},scrollPaymentVertical:{},scrollconfirmedVertical:{},scrollconfirmedHorizontal:{}, welcomescreen:null,payment_completed:{}, getPrePaymentOrder:{},response: data.data,eventShowTimes:{},searchresponse:{},seatdate:{},get_ticket_type:{},seatoverview:{},scrollseatmapleft:{},scrollseatmaptop:{} });
    case "trigger_event":
      return this.setState({triggerevent:data.ticket_result})
    case "get_ticket_info_onchange":
      return this.setState({get_ticket_info_onchange:data.ticket_result,triggerevent:{}})
    case "key_pairing":
      return this.setState({first_screen_user_key:data.first_screen_user_key,session_key:data.client_key,client_key:data.session_key,keypaired:1})
    case "scrollPaymentVertical":
      return this.setState({scrollPaymentVertical:data.data});
    case "scrollpaymentHorizontal":
      return this.setState({scrollpaymentHorizontal:data.data});
    // case "scrollagreeVertical":
    //   return this.setState({scrollagreeVertical:data.data,controlagreeclose:null,controlagreeopen:null});
    // case "scrollagreeHorizontal":
    //   return this.setState({scrollagreeHorizontal:data.data,controlagreeclose:null,controlagreeopen:null,});
    case "scrollbookingVertical":
      return this.setState({scrollconfirmedVertical:data.data});
    case "scrollbookingHorizontal":
      return this.setState({scrollconfirmedHorizontal:data.data});
    case "scrollseatmaptop":
      return this.setState({scrollseatmaptop:data.data});
    case "scrollseatmapleft":
      return this.setState({scrollseatmapleft:data.data});
    case "getEventsSearch":
      return this.setState({pricedetails:{},session_key:data.client_key,client_key:data.session_key,calender_page:null,scrollconfirmedHorizontal:{}, scrollseatmaptop:{},scrollseatmapleft:{},scrollconfirmedVertical:{},scrollpaymentHorizontal:{},scrollPaymentVertical:{},scrollconfirmedVertical:{},scrollconfirmedHorizontal:{},  scrollseatmaptop:{},scrollseatmapleft:{},searchresponse: data.data,response:{},eventShowTimes:{},seatdate:{},get_ticket_type:{} });
    case "checkedonoff":
      return this.setState({checkonoff:data.data, controlagreeopen:null,controlagreeclose:null});
    case 'agree_update':
      return this.setState({ controlagreeclose:data.data,controlagreeopen:null });
    case 'agree_open':
      return this.setState({ controlagreeopen:data.data,controlagreeclose:null });
    case "agree_checkbox":
      return this.setState({ agree_checkbox:data.data,controlagreeopen:null,controlagreeclose:null,payment_error:"" });
    case 'scrolltable':
      return this.setState({scrollvalue:data.data});
    case 'seat_Offer':
      return this.setState({seat_data:data ,get_ticket_info_onchange :{}});
    case 'get_event_show_date_and_time':
      return this.setState({ date_and_time_control:data,print_transaction:null });
    case "get_pre_payment":
      return this.setState({pricedetails:{},agree_checkbox:false,session_key:data.client_key,print_transaction:null,client_key:data.session_key,get_ticket_info_onchange :{},calender_page:null,Select_PaymentMethod:null,welcomescreen:null,getPrePaymentOrder:data,controlagreeclose:null,controlagreeopen:null, response:{},searchresponse:{},scrollconfirmedHorizontal:{}, scrollseatmaptop:{},scrollseatmapleft:{},scrollconfirmedVertical:{},scrollpaymentHorizontal:{},scrollPaymentVertical:{},scrollconfirmedVertical:{}, scrollseatmaptop:{},scrollseatmapleft:{},scrollconfirmedHorizontal:{},eventShowTimes:{},seatdate:{},controlagreeclose:null,controlagreeopen:null,agree_checkbox:false});
    case "payment_completed":
      return this.setState({seat_data:{},payment_completed:data,controlagreeclose:null,get_ticket_info_onchange :{},session_key:data.client_key,client_key:data.session_key,print_transaction:null});
    case "print_transaction":
        return this.setState({print_transaction:data});
    case "print_status":
        if(data.data === "success")
        return this.setState({session_key:data.client_key,client_key:data.session_key});//payment_completed:{},welcomescreen:data.data
    case "getEventsSearch_title":
        return this.setState({searchtitle:data});
    case "get_event":
        return this.setState({pricedetails:{},session_key:data.client_key,client_key:data.session_key,get_ticket_info_onchange :{},calender_page:null,scrollconfirmedHorizontal:{}, scrollseatmaptop:{},scrollseatmapleft:{},scrollconfirmedVertical:{},scrollpaymentHorizontal:{},scrollPaymentVertical:{}, scrollseatmaptop:{},scrollseatmapleft:{} ,scrollconfirmedVertical:{},scrollconfirmedHorizontal:{}, scrollseatmaptop:{},scrollseatmapleft:{}, welcomescreen:null,payment_completed:{}, getPrePaymentOrder:{},response: data.data,eventShowTimes:{},searchresponse:{},seatdate:{},get_ticket_type:{},seatoverview:{} });
    case "get_event_times":
      return this.setState({pricedetails:{},session_key:data.client_key,client_key:data.session_key,get_ticket_info_onchange :{},calender_page:null,welcomescreen:null,payment_completed:{},getPrePaymentOrder:{},eventShowTimes: data.data,response:{},searchresponse:{},scrollconfirmedHorizontal:{},scrollconfirmedVertical:{},scrollpaymentHorizontal:{},scrollPaymentVertical:{},scrollconfirmedVertical:{},scrollconfirmedHorizontal:{},seatdate:{},get_ticket_type:{},seatoverview:{} });
    case "priceCategory_list":
      return this.setState({session_key:data.client_key,client_key:data.session_key,priceCategory_list: data.data }); 
    case "calender_page":
      return this.setState({calender_seat_selection:null,seat_data:{},session_key:data.client_key,client_key:data.session_key,calender_page:data.data,welcomescreen:null,payment_completed:{},getPrePaymentOrder:{},scrollconfirmedHorizontal:{}, scrollseatmaptop:{},scrollseatmapleft:{},scrollconfirmedVertical:{},scrollpaymentHorizontal:{},scrollPaymentVertical:{},scrollconfirmedVertical:{},scrollconfirmedHorizontal:{},searchresponse:{},seatdate:{},get_ticket_type:{},get_ticket_info_onchange :{}}); 
    case "payment_details":
      return this.setState({payment_details: data.data });  
    case "Select_PaymentMethod":
      return this.setState({Select_PaymentMethod: data.data.paymentMethodName, Selected_PaymentMethods: data.data,get_ticket_info_onchange :{} }); 
    case "get_ticket_price_info":
      return this.setState({session_key:data.client_key,client_key:data.session_key,get_ticket_price_info:data,get_ticket_info_onchange :{}});
    default:
      return this.state;
    
    }
  });
  window.addEventListener("beforeunload", (ev) => 
    {
      let THIS=this;
      const { endpoint } = THIS.state;
     
      const socket = socketIOClient(endpoint,{ transports: ['polling']});
      socket.on('connect', function () {
        socket.emit('control_response', {client_key: THIS.state.first_screen_user_key,first_screen_user_key: THIS.state.first_screen_user_key,value:"remove_client_key"});
      });
      return ev.returnValue='Leaving Second Screen?';
      //ev.preventDefault();
    });
  }
  componentDidMount(){
    console.log("endpoint",process.env.REACT_APP_ENDPOINT)
    window.addEventListener("beforeunload", (ev) => 
    {
      let THIS=this;
      const { endpoint } = THIS.state;
      const socket = socketIOClient(endpoint,{ transports: ['polling']});
      socket.on('connect', function () {
        socket.emit('control_response', {client_key: THIS.state.first_screen_user_key,first_screen_user_key: THIS.state.first_screen_user_key,value:"remove_client_key"});
      
      });
     
      return ev.returnValue='Leaving Second Screen?';
      //ev.preventDefault();
    });
  }
//PaymentLanguageconversion

languageconversion_onpayment(data,indexval){
  if(data !== null)
  {
  var remove_brtag=data.split("<br/>");
  remove_brtag=remove_brtag[0];
  let pipe_split=remove_brtag.split("|");
  if(pipe_split[indexval] !== undefined)
  {
    

    return pipe_split[indexval];

  }else{
    return pipe_split[0].trim();
  }

  }
  
}


  languageconversion(str,indexval){
  if(str !== undefined)
  {
  let result=str.split("|");
 
  if(result[indexval] !== undefined)
  {
    let result2;
    if(indexval === 2){ 
        return result[indexval];
    }
    else{
      if(result[2] !== undefined)
      {
        result2=result[2].split("-")
        if(result2[1] !== undefined)
        return result[indexval]+"-"+result2[1];
        else
        return result[indexval];
      }
      else
      {
        return result[indexval];
      }
    }
  }
  else
  {
    return result[0];
  }
}
}

imagekeyvalidations(str)
{
  if(str === undefined)
    return "";
  else
    return process.env.REACT_APP_IMGPATH+str;
  
}


showModaldialog(str){
window.$("#"+str).modal('hide');
window.$("#"+str).modal('show');
window.$('.modal-backdrop').add();
}
hideModaldialog(str){
  if(window.$)
  window.$("#"+str).modal('hide');
  //window.$('.modal-backdrop').remove();
}
imageUpdate()
{
  $("#imgClickAndChange").attr("src","/images/checked-circle.png");
}
callEdcError(str){
  $(".loading-wrap").removeClass("d-none")
  setTimeout(function() { $(".loading-wrap").addClass("d-none"); }, 4000);
  this.setState({paymenterror:""})
}
render() {
  let { language_conversions,seatoverview,triggerevent,languageindex, mymodalValue,get_ticket_info_onchange,checkonoff,payment_details,calender_page,get_ticket_price_info, searchresponse,date_and_time_control,payment_completed,scrollvalue,searchtitle,print_transaction,seat_data,controlpaymentopen,controlpaymentclose,response,priceCategory_list,welcomescreen,controlagreeclose,controlagreeopen,get_ticket_type,seatdata,seatdate,eventShowTimes,images } = this.state; 
  let { scrollseatmaptop,scrollseatmapleft }=this.state;
  if(controlagreeopen  && (controlagreeopen !== 2))
	{
    window.$(".loading-wrap").addClass("d-none"); 
  	var str='myModal';
    this.showModaldialog(str);  
  }
  // if(Object.keys(this.state.getPrePaymentOrder).length > 0){
  //   var str='myModal';
  //   this.hideModaldialog(str);
  // }
  if(this.state.paymenterror !== ""){
    this.callEdcError(this.state.paymenterror);
  }
  let selected_time,summaryimage,eventShowTimes_title;
  if(Object.keys(date_and_time_control).length > 0){
    selected_time=moment(date_and_time_control.eventStartDate).format("h:mm A");
    if(date_and_time_control.summaryImagePath !== ""){ // ST-12659
      localStorage.setItem("summaryImage",date_and_time_control.summaryImagePath)
    }
  }

  // if(Object.keys(eventShowTimes).length > 0){
  //   eventShowTimes && eventShowTimes.map((eventdetails,i)=>{
  //     eventShowTimes_title=eventdetails.title;
  //     eventShowTimes_title=eventShowTimes_title.split("-");
  //     eventShowTimes_title=eventShowTimes_title[0];
  //     if(eventShowTimes_title == selected_time ){
  //         if(eventdetails.summaryImagePath === ""){
  //           if(!localStorage.getItem("summaryImage")) {
  //             localStorage.setItem("summaryImage","/images/sistic.png")
  //           }
  //         }else{
  //           localStorage.setItem("summaryImage",eventdetails.summaryImagePath)
  //         }
  //     }
     
      
  //   })
  // }
  // [START] ST-12241

  if(Object.keys(images.logoImage).length > 0) {
    localStorage.setItem("logoImage", images.logoImage)
  } else {
    localStorage.removeItem("logoImage");
  }

  if(Object.keys(images.defaultEventImage).length > 0) {
    console.log("images.summaryImagePath...." + images.defaultEventImage);
    if(Object.keys(date_and_time_control).length > 0 ){ // ST-12659
      if (date_and_time_control.summaryImagePath === "" && images.defaultEventImage != "") {
          localStorage.setItem("summaryImage", images.defaultEventImage)
      }
    } else {
      if(images.defaultEventImage != "") {
        localStorage.setItem("summaryImage", images.defaultEventImage)
      }
    }
  } else {
    localStorage.removeItem("summaryImage");
  }

  if(Object.keys(images.defaultSeatmapImage).length > 0) {
    if(images.defaultSeatmapImage != "") {
      localStorage.setItem("seatMapOverviewImg", images.defaultSeatmapImage)
    }
  } else {
    localStorage.removeItem("seatMapOverviewImg");
  }
  // [END] ST-12241
  if(this.state.agree_checkbox)
  {
  $("#imgClickAndChange").attr("src","/images/checked-circle.png");
  }
  else
  {
  
    $("#imgClickAndChange").attr("src","/images/check-circle.png");
  }
  console.log("controlagreeopen",controlagreeopen)
	if(!controlagreeopen){
    var str='myModal';
    this.hideModaldialog(str);
  }

  if(print_transaction){
    $(".loading-wrap").removeClass("d-none"); 
    setTimeout(function() { $(".loading-wrap").addClass("d-none"); }, 2000);
  }else{
    if(this.state.keypaired ){
    
      if((this.state.session_key === this.state.randomString)){
        let successmessage="successfully Paired To Device";
        setTimeout(function() { $(".paired").addClass("d-none"); }, 1000);
        if(this.state.keypaired === 1)
        this.setState({keypaired:2});
      }
      
    }
  }
  
  if(this.state.keypaired ){
    
    if((this.state.session_key === this.state.randomString)){
      let successmessage="successfully Paired To Device";
      setTimeout(function() { $(".paired").addClass("d-none"); }, 1000);
      if(this.state.keypaired === 1)
      this.setState({keypaired:2});
    }
    
  }
  let scrollconfirmedHorizontal = this.state.scrollconfirmedHorizontal ?this.state.scrollconfirmedHorizontal : this.state.scrollpaymentHorizontal;
  return (
    checkonoff?<div style={{height:'100vh',width:'100vw'}}>    
    { this.state.images.startupImgs.length > 1 ? 
        <AliceCarsourel autoPlay infinite autoPlayStrategy="none" autoPlayInterval={images.startUpImgAutoPlayInterval} disableButtonsControls disableDotsControls >
        { this.state.images.startupImgs.map((startupImg, i) => {
          return (<img key= {i} src={this.imagekeyvalidations(startupImg)} className="sliderimg" />);
        }) }
      </AliceCarsourel> 
      : this.state.images.startupImgs.length == 1 ? 
        <img src={this.imagekeyvalidations(this.state.images.startupImgs[0])} style={{maxHeight: '100%',width:'100%',display: 'block',marginLeft: 'auto',marginRight: 'auto'}} />
        : <span></span>
    }
    </div>:
    Object.keys(this.state.payment_completed).length > 0 && ((this.state.randomString === this.state.session_key)) ?<Bookingconfirmed language_conversions={language_conversions} languageindex={this.state.languageindex} scrollconfirmedHorizontal={scrollconfirmedHorizontal} scrollconfirmedVertical={this.state.scrollconfirmedVertical} seat_data={this.state.seat_data}  get_ticket_type={this.state.get_ticket_type}  languageconversion={this.languageconversion} payment_completed={this.state.payment_completed} date_and_time_control={this.state.date_and_time_control} getPrePaymentOrder={this.state.getPrePaymentOrder} Selected_PaymentMethods={this.state.Selected_PaymentMethods} eventShowTimes={this.state.eventShowTimes} imagekeyvalidations={this.imagekeyvalidations} languageconversion_onpayment={this.languageconversion_onpayment} />:
    Object.keys(this.state.getPrePaymentOrder).length > 0 && ((this.state.randomString === this.state.session_key)) ?<PaymentOption language_conversions={language_conversions} controlagreeopen={this.state.agreedata} agree_checkbox={this.state.agree_checkbox}  languageindex={this.state.languageindex} scrollagreeHorizontal={this.state.scrollagreeHorizontal} scrollPaymentVertical={this.state.scrollPaymentVertical} scrollpaymentHorizontal={this.state.scrollpaymentHorizontal} scrollagreeVertical={this.state.scrollagreeVertical} languageconversion={this.languageconversion} languageconversion_onpayment={this.languageconversion_onpayment} Select_PaymentMethod={this.state.Select_PaymentMethod} Selected_PaymentMethods={this.state.Selected_PaymentMethods} payment_details={this.state.payment_details} seat_data={this.state.seat_data}  get_ticket_type={this.state.get_ticket_type} date_and_time_control={this.state.date_and_time_control} getPrePaymentOrder={this.state.getPrePaymentOrder} eventShowTimes={this.state.eventShowTimes} imagekeyvalidations={this.imagekeyvalidations}/>:
    Object.keys(this.state.seatdate).length > 0  && ((this.state.randomString === this.state.session_key))   ?<ChooseSeat language_conversions={language_conversions} languageindex={this.state.languageindex} scrollseatmaptop={this.state.scrollseatmaptop} scrollseatmapleft={this.state.scrollseatmapleft} zoomdata={this.state.zoomdata} triggerevent={this.state.triggerevent}  get_ticket_info_onchange={this.state.get_ticket_info_onchange} get_ticket_price_info= {this.state.get_ticket_price_info} languageconversion={this.languageconversion} seat_data={this.state.seat_data} date_and_time_control={this.state.date_and_time_control} seatoverview={this.state.seatoverview} seatdate={this.state.seatdate} eventShowTimes={this.state.eventShowTimes} imagekeyvalidations={this.imagekeyvalidations} basicConfig={this.state.basicConfig} />:
    Object.keys(this.state.pricedetails).length > 0  && ((this.state.randomString === this.state.session_key)) ?<CategoryList language_conversions={language_conversions} languageindex={this.state.languageindex} pricedetails={ this.state.pricedetails } scrollvalue={this.state.scrollvalue} seat_data={this.state.seat_data} date_and_time_control={this.state.date_and_time_control} seatdata={this.state.seatoverview} eventShowTimes={this.state.eventShowTimes} languageconversion={this.languageconversion} imagekeyvalidations={this.imagekeyvalidations}  />:
    Object.keys(this.state.eventShowTimes).length > 0 && ((this.state.randomString === this.state.session_key)) ?<SelectDate language_conversions={language_conversions} scrollvalue={this.state.scrollvalue} calender_seat_selection={this.state.calender_seat_selection}  languageindex={this.state.languageindex} priceCategory_list={this.state.priceCategory_list} date_and_time_control={this.state.date_and_time_control} eventShowTimes={this.state.eventShowTimes} imagekeyvalidations={this.imagekeyvalidations} languageconversion={this.languageconversion}  />:
    Object.keys(this.state.searchresponse).length > 0 && ((this.state.randomString === this.state.session_key)) ?<div style={{height:'100vh',width:'100vw'}}>
    { this.state.images.startupImgs.length > 1 ? 
      <AliceCarsourel autoPlay infinite autoPlayStrategy="none" autoPlayInterval={images.startUpImgAutoPlayInterval} disableButtonsControls disableDotsControls >
      { this.state.images.startupImgs.map((startupImg, i) => {
        return (<img key= {i} src={this.imagekeyvalidations(startupImg)} className="sliderimg" />);
      }) }
      </AliceCarsourel> 
    : this.state.images.startupImgs.length == 1 ? 
      <img src={this.imagekeyvalidations(this.state.images.startupImgs[0])} style={{maxHeight: '100%',width: '100%',display: 'block',marginLeft: 'auto',marginRight: 'auto'}} />
      : <span></span>
    }
    <Pairing 
      pairingId={language_conversions[languageindex]["paringid"]} 
      randomString={this.state.randomString} 
      sccessmsg={this.state.successmessage} />
    {/* <div className="loading-wrap paired">
        <div className="loading-container ">
       
        <p><h2>{language_conversions[languageindex]["paringid"]}</h2></p>
        <h4 >{ this.state.randomString}</h4>
        <h4>{this.state.successmessage}</h4>
        <h4></h4>
        </div>
      </div> */}
    </div>:
    Object.keys(this.state.response).length > 0 ?<div style={{height:'100vh',width:'100vw'}}>
    { this.state.images.startupImgs.length > 1 ? 
        <AliceCarsourel autoPlay infinite autoPlayStrategy="none" autoPlayInterval={images.startUpImgAutoPlayInterval} disableButtonsControls disableDotsControls >
        { this.state.images.startupImgs.map((startupImg, i) => {
          return (<img key= {i} src={this.imagekeyvalidations(startupImg)} className="sliderimg" />);
        }) }
      </AliceCarsourel> 
      : this.state.images.startupImgs.length == 1 ? 
        <img src={this.imagekeyvalidations(this.state.images.startupImgs[0])} style={{maxHeight: '100%',width: '100%', display: 'block',marginLeft: 'auto',marginRight: 'auto'}} />
        : <span></span>
    } 

    <Pairing 
      pairingId={language_conversions[languageindex]["paringid"]} 
      randomString={this.state.randomString} 
      sccessmsg={this.state.successmessage} 

      />
    {/* <div className="loading-wrap paired">
        <div className="loading-container ">
       
        <h2>{language_conversions[languageindex]["paringid"]}</h2>
        <h4 style={{fontFamily: 'monospace'}}>{ this.state.randomString}</h4>
        <h4>{this.successmessage}</h4>
        </div>
      </div> */}
    </div>:
    <div style={{height:'100vh',width:'100vw'}}>
    { this.state.images.startupImgs.length > 1 ? 
        <AliceCarsourel autoPlay infinite autoPlayStrategy="none" autoPlayInterval={images.startUpImgAutoPlayInterval} disableButtonsControls disableDotsControls >
        { this.state.images.startupImgs.map((startupImg, i) => {
          return (<img key= {i} src={this.imagekeyvalidations(startupImg)} className="sliderimg" />);
        }) }
      </AliceCarsourel> 
      : this.state.images.startupImgs.length == 1 ? 
        <img src={this.imagekeyvalidations(this.state.images.startupImgs[0])} style={{maxHeight: '100%',width: '100%', display: 'block',marginLeft: 'auto',marginRight: 'auto'}} />
        : <span></span>
    }
    <Pairing 
      pairingId={language_conversions[languageindex]["paringid"]} 
      randomString={this.state.randomString} 
      sccessmsg={this.state.successmessage} />
    {/* <div className="loading-wrap paired">
      <div className="loading-container ">

      <h2>{language_conversions[languageindex]["paringid"]}</h2>
      <h4 >{ this.state.randomString}</h4>
      <h4>{this.state.successmessage}</h4>
      <h4></h4>
      </div>
    </div> */}
    </div>
  );
}
}
export default WhiteLabel;

// // welcomescreen?<Welcome />: